import styled from '@emotion/styled';
import { Grid } from '@mui/material';

const RightSide = styled(Grid)`
  display: flex;
  width: 100%;
  height: 100%;
  background: var(--content-primary, #20233d);
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export default RightSide;
