import VisionXLogo from './VisionXLogo';
import LeftSide from './LeftSide';
import RightSide from './RightSide';
import ImgBanner from './ImgBanner';
import { ReactNode, useEffect } from 'react';
import { Grid } from '@mui/material';
import type { KcContext } from '../login/kcContext';

interface GeminiTemplateProps {
  children: ReactNode;
  kcContext: KcContext;
}

const Layout = ({ children, kcContext }: GeminiTemplateProps): JSX.Element => {
  const { displayLogo, title } = kcContext.properties;

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Grid container spacing={0} sx={{ height: '100%' }}>
      <LeftSide item xs={6}>
        {displayLogo === 'true' ? <VisionXLogo /> : null}
        {children}
      </LeftSide>
      <RightSide item xs={6}>
        <ImgBanner />
      </RightSide>
    </Grid>
  );
};

export default Layout;
