import { Global, css } from '@emotion/react';

const FontFaces = (): JSX.Element => {
  return (
    <Global
      styles={css`
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');
        @import url('https://fonts.cdnfonts.com/css/pp-neue-montreal');
      `}
    />
  );
};

export default FontFaces;
