import styled from '@emotion/styled';
import { Grid } from '@mui/material';

const LeftSide = styled(Grid)`
  height: 100%;
  display: flex;
  position: relative;
`;

export default LeftSide;
