import Layout from './Layout';
import { kcContext } from '../login/kcContext';
import { lazy } from 'react';

const KcLoginThemeApp = lazy(() => import('../login/KcApp'));

const NoMockPageError = (): JSX.Element => {
  return (
    <div
      style={{
        margin: 'auto',
        textAlign: 'center',
        background: '#dd6d6d',
        padding: '10px',
        fontWeight: 'bold',
      }}
    >
      No page has been defined. Did you forget to define the
      REACT_APP_MOCK_PAGE_ID in your .env file?
    </div>
  );
};

const Router = (): JSX.Element => {
  if (kcContext !== undefined) {
    return (
      <Layout kcContext={kcContext}>
        <KcLoginThemeApp kcContext={kcContext} />;
      </Layout>
    );
  }

  return <NoMockPageError />;
};

export default Router;
