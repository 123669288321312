import {
  Theme as MuiTheme,
  PaletteOptions,
  SimplePaletteColorOptions,
  ThemeOptions,
  createTheme,
} from '@mui/material';

declare module '@emotion/react' {
  export interface Theme extends MuiTheme {}
}

interface PaletteOptionsOverrided extends PaletteOptions {
  primary?: SimplePaletteColorOptions;
}

const palette: PaletteOptionsOverrided = {
  primary: {
    main: '#20233D',
    contrastText: '#20FFFA',
  },
  secondary: {
    main: '#4D4D4D',
  },
  text: {
    primary: '#20233D',
    secondary: '#808191',
  },
};

const typography: ThemeOptions['typography'] = {
  fontFamily: 'PP Neue Montreal',
  button: {
    textTransform: 'none',
  },
  h1: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: '-0.32px',
    fontStyle: 'normal',
  },
  h3: {
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '24px',
    letterSpacing: '0.03px',
    fontStyle: 'normal',
  },
};

const components: ThemeOptions['components'] = {
  MuiAlert: {
    styleOverrides: {
      root: {
        '& .MuiAlert-message': {
          width: '100%',
          textAlign: 'center',
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& .MuiInputLabel-root': {
          color: '#4B4D6E',
        },
        '& .MuiInputBase-root': {
          borderRadius: '10px',
          '& .MuiInputBase-input': {
            padding: '16px',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            letterSpacing: '0.028px',
          },
          '& fieldset': {
            borderColor: 'inherit',
          },
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: palette.primary?.main,
        textDecorationColor: 'inherit',
        textUnderlineOffset: '3px',
        fontFamily: 'PP Neue Montreal', // idk why I need to setup here
        fontWeight: 500,
        lineHeight: '16px',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        '&.MuiButton-contained': {
          borderRadius: '10px',
          '&:hover': {
            backgroundColor: palette.primary?.main,
          },
        },
        '&.Mui-disabled': {
          color: palette.primary?.contrastText,
          backgroundColor: palette.primary?.main,
          opacity: 0.7,
        },
      },
    },
  },
};

const theme = createTheme({
  palette,
  typography,
  components,
});

export default theme;
