import src from '../assets/img/visionx-banner.png';
import styled from '@emotion/styled';

const Style = styled.img`
  display: flex;
  width: 100%;
  height: 90%;
`;

const ImgBanner = (): JSX.Element => <Style src={src} />;

export default ImgBanner;
