import FontFaces from './components/FontFaces';
import ResetCSS from './components/ResetCss';
import Router from './components/Router';
import theme from './providers/theme';
import { createRoot } from 'react-dom/client';
import { StrictMode, Suspense } from 'react';
import { ThemeProvider } from '@emotion/react';

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Suspense>
      <FontFaces />
      <ResetCSS />
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </Suspense>
  </StrictMode>,
);
