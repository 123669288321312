import src from '../assets/img/visionx.svg';
import styled from '@emotion/styled';

export const Style = styled.img`
  position: absolute;
  top: 50px;
  left: 50px;
`;

const VisionXLogo = (): JSX.Element => <Style src={src} />;

export default VisionXLogo;
